<template>
    <div class="custompPropertiesPanel">

        <div class="custompPropertiesPanel_processName">{{!selectionObj.newSelection || selectionObj.newSelection.length != 1 ? '主流程' : getProcessName}}</div>
        <input-or-output 
            :notEdit="notEdit" 
            :activeElement="activeElement"
            :updataElement="updataElement"
            @change="changeHandler">
        </input-or-output>
        <div class="custompPropertiesPanel_form">
         
            <div class="custompPropertiesPanel_form_table" v-if="activeElement && activeElement.type == 'bpmn:Process'">
                <label class="form_cell_lable">变量</label>
                <el-table
                    class="divider_table"
                    :data="dividerVar"
                    row-key="id"
                    border
                    row-class-name="divider_tr"
                    style="width: 100%">
                    <el-table-column>
                    </el-table-column>
                    <el-table-column
                        prop="type"
                        align="center">
                    </el-table-column>
                    <el-table-column
                        align="center">
                    </el-table-column>
                    <el-table-column
                        align="center">
                    </el-table-column>

                </el-table>
                

                <el-table
                    class="data_table"
                    :data="mainProcessVar"
                    border
                    :row-class-name="tableRowClassName"
                    row-key="code"
                    style="width: 100%">
                    <el-table-column
                        class="divider"
                        align="center"
                        label="标识">
                        <template slot-scope="scope" >
                            <el-input 
                                v-if="scope.row.type != 'splitLine'"
                                class="form_table_input" 
                                v-model="scope.row.id"  
                                :disabled="notEdit"
                                placeholder="请输入标识"
                                @change="updataMianProcess(scope.row)" 
                                type="text">
                            </el-input>
                            <span v-else>{{scope.row.id}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        align="center"
                        label="类型">
                        
                        <template slot-scope="scope">
                            <el-select 
                                v-if="scope.row.type != 'splitLine'"
                                :disabled="notEdit"
                                class="form_cell_select" 
                                placeholder="请选中类型" 
                                @change="updataMianProcess(scope.row)"
                                v-model="scope.row.type">
                                <el-option
                                    v-for="option in dataTypeList"
                                    :key="option.type"
                                    :value="option.type"
                                    >{{ option.type }}</el-option>
                            </el-select>
                            <span v-else>{{scope.row.type}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        align="center"
                        label="必填"
                        width="60">
                        
                        <template slot-scope="scope">
                            <el-checkbox
                                v-if="scope.row.type != 'splitLine'"
                                class="form_cell_checkbox"
                                :disabled="notEdit"
                                @change="updataMianProcess(scope.row, 'checkbox')" 
                                v-model="scope.row.check"></el-checkbox>
                        </template>
                    </el-table-column>
                    <el-table-column
                        align="center"
                        label="默认值">
                        <template slot-scope="scope">
                            <el-input  
                                v-if="scope.row.type != 'splitLine'"
                                class="form_table_input" 
                                :rows="1"
                                type="textarea"
                                v-model="scope.row.default" 
                                :disabled="notEdit || scope.row.check"
                                @change="updataMianProcess(scope.row)">
                            </el-input>
                        </template>
                    </el-table-column>
                    <el-table-column
                        align="center"
                        label="说明">
                        <template slot-scope="scope">
                            <el-input  
                                v-if="scope.row.type != 'splitLine'"
                                class="form_table_input" 
                                v-model="scope.row.desc" 
                                type="textarea"
                                autosize
                                :disabled="notEdit"
                                @change="updataMianProcess(scope.row)">
                            </el-input>
                        </template>
                    </el-table-column>
                    <el-table-column
                        v-if="!notEdit"
                        align="center"
                        label="	操作">
                        <template slot-scope="scope">
                            <div 
                                class="table_cell_btn">
                                <span class="removeOrAddVar"  @click="removeOrAddVar(scope.$index, scope.row)">删除</span>
                                <span class="removeOrAddVar"  @click="removeOrAddVar(null, scope.row)">增加</span>
                            </div>
                        </template>
                    </el-table-column>

                    <template slot="empty">
                        
                        <div class="emptyVar" @click="removeOrAddVar(null)" v-if="mainProcessVar.length < 2">
                            <span >添加变量</span>
                        </div>
                    </template>
                </el-table>
                
            </div>
        </div>

    </div>
</template>

<script>
    import CommandStack from 'diagram-js/lib/command/CommandStack';
    import { toRaw, toRefs } from 'vue';
    import { shapeAction } from '../actionsList';
    import { dataTypes } from './util'
    import momemt from 'moment'
    import inputOrOutput from './inputOrOutput.vue'; // 出入参组件

    import Sortable from 'sortablejs';
    export default {
        name: 'custompPropertiesPanel',
        components: {
            inputOrOutput
        },
        props: {
            // bpmn实例
            bpmnModeler: {
                type: Object,
                default: () => ({})
            },
            // 主流程标识
            processId: {
                type: String,
                default: 'Process_1',
            },
            // 主流程标识
            processName: {
                type: String,
                default: '方案名称',
            },
            // notEdit == true 不可以编辑
            notEdit: {
                type: Boolean,
                default: true, 
            }
        },
        data() {
            return {
                selectionObj: [], // 当前及上次选择的元素集合
                activeElement: null, // 当前点击的元素
                updataElement: false, // 是否更新元素
                attrForm: { // 流程属性值
                    id: '', // 流程ID标识
                    name: '', // 流程name
                    taskType: '', // 任务类型
                    subtype: '', // 子类型类型
                    subProcessType: '', // 子流程
                    conditionExpression: '', // 条件表达式
                },
                mainProcessVar: [],   // 主流程变量
                dataTypeList: dataTypes,
                dividerVar: [{
                    id: 'divider',
                    type: 'splitLine',
                    must: false,
                    default: '',
                }],
                dividerNUm: 0, // 获取元素次数
            }
        },
        provide() {
            return {
                topThis: this
            }
        },
        inject: ['parentThis'],
        computed: {

            // 流程节点元素名称
            getProcessName() {
                const { type } = this.activeElement;
                const shapeData = shapeAction.find(item => item.action[0] == type);
                return shapeData?.action[shapeData.action.length - 1] ?? '';
            },


        },
        watch: {
        },
        created() {
            // 初始化属性面板
            this.init();
        },
        mounted() {
        },
        methods: {

            // 为 Table 中的分割线添加 class
            tableRowClassName({row, rowIndex}) { 
                
                if (row.type === 'splitLine') {
                    return 'divider_tr';
                }
                return '';
            },

             // 行拖拽排序 
             tableRowDrop() {
                

                // 此时找到的元素是要拖拽元素的父容器 
                const dividerbody = document.querySelector('.divider_table tbody'); 
                if (!dividerbody) {
                    if (this.dividerNUm < 10) {
                        this.dividerNUm++;
                        setTimeout(() => {
                            this.tableRowDrop();
                        }, 200)
                    }
                    return
                }
                Sortable.create(dividerbody, { 
                    // 指定父元素下可被拖拽的子元素 
                    group: {
                        name: 'divider',
                        pull: 'clone',
                        put: false // 不允许拖拽进这个列表
                    },
                    draggable: ".el-table__row", 
                    animation: 150,
                    ghostClass: 'active-background-class',
                    // 结束拖拽
                    onEnd: evt => {
                        const trList = document.querySelectorAll('.data_table tbody tr');
                        if (trList.length) {
                            const firsrDom = trList[evt.newIndex];
                            firsrDom.remove()
                            console.log(this.mainProcessVar, 'onEnd')
                            this.mainProcessVar.splice(evt.newIndex, 0, {
                                    id: this.randomString(),
                                    code: this.randomString(),
                                    type: 'splitLine',
                                    must: false,
                                    default: '',
                                    desc: '',
                                })
                            this.updataMianProcess(this.mainProcessVar[evt.newIndex])
                        }
                    },
                }); 
                

                // 此时找到的元素是要拖拽元素的父容器 
                const tbody = document.querySelector('.data_table tbody');
                Sortable.create(tbody, { 
                    // 指定父元素下可被拖拽的子元素 
                    group: {
                        name: 'divider',
                    },
                    draggable: ".el-table__row", 
                    animation: 150,
                    ghostClass: 'active-background-class',
                    // 结束拖拽
                    onEnd: evt => {


                        console.log('拖动了行，序号(index)"'+evt.oldIndex+'"拖动到序号(index)"' + evt.newIndex+'"'); 

                        const className = evt.clone.className;
                        
                        if (className.indexOf('divider_tr') > -1 && evt.newIndex == 0) {
                            this.mainProcessVar.splice(evt.oldIndex, 1);
                            
                            // const trList = document.querySelectorAll('.data_table tbody tr');
                            // const firsrDom = trList[0];
                            // firsrDom.remove();
                        } else {
                            const currentRow = this.mainProcessVar.splice(evt.oldIndex, 1)[0]; // 将oldIndex位置的数据删除并取出，oldIndex后面位置的数据会依次前移一位 
                            this.mainProcessVar.splice(evt.newIndex, 0, currentRow); // 将被删除元素插入到新位置（currRow表示上面的被删除数据）
                        }
                        
                        this.updataMianProcess(this.mainProcessVar[evt.oldIndex])
                        
                    }
                }); 
                
                
            },

            /**
             * 初始化属性面板
             * @param {Object} val 父组件传递进来的 bpmnModeler实例
             */
            init() {
                
                const { bpmnModeler } = this;
                const elementRegistry = bpmnModeler.get('elementRegistry');
                const that = this;
                bpmnModeler.on('selection.changed', (e) => {
                    const mamProxess = elementRegistry.get(this.processId);
                    that.selectionObj = toRaw(e); // 数组, 可能有多个
                    let temp = toRaw(e.newSelection.length > 0 ? e.newSelection[0] : mamProxess) // 默认取第一个
                    
                   

                    that.activeElement = temp
                    
                    if (!that.mainProcessVar.length && that.activeElement) {
                        
                        const { type } = that.activeElement;
                        if (['bpmn:StartEvent', 'bpmn:EndEvent'].includes(type) || type != 'bpmn:Process') return;
                        
                        const tempValues = that?.activeElement?.businessObject?.extensionElements?.values ?? [];
                        
                        tempValues.forEach(item => {
                            item.check = item?.must ?? false;
                            item.code = item?.code ?? this.randomString();
                            if (!item.id && !item.type && !item.must && !item.default) {
                                item.id = this.randomString();
                                item.code = this.randomString();
                                item.type = 'splitLine';
                                item.must = false;
                                item.default = '';
                            }
                            
                            that.mainProcessVar.push(JSON.parse(JSON.stringify(item)))
                        });
                    }
                    if (temp && temp.type == 'bpmn:Process') {
                        this.$nextTick(() => {
                            this.tableRowDrop()
                        })
                    }
                    
                    
                })
                let tempName = '';
                bpmnModeler.on('element.changed', e => {
                    const { element } = e
                    const { activeElement } = that;
                    if (!activeElement) {
                        return
                    }
                    if (element.id === activeElement.id) {
                        that.activeElement = toRaw(element);
                    }
                  
                    const tempNode = toRaw(element)
                    
                    const name = tempNode?.businessObject?.name ?? '';
                    // if (tempNode.type == 'bpmn:SequenceFlow' && tempName != name) {
                        
                    //     tempName = name
                    //     that.conditionExpression(tempName, element)
                    // }
                    
                })
                bpmnModeler.on('element.mousedown', e => {
                    
                    if (e?.element?.id == that?.activeElement?.id) {
                        this.selection();
                    }
                    
                    
                })
                document.querySelector('.bpmn_canvas').click()
            },
            


            /**
             * 选中的元素
             * 如果是子流程，将调用祖级事件
             */
            selection() {
                
                if (this.activeElement && this.activeElement.type == 'bpmn:SubProcess') {
                    this.$parent.subProcessView(toRaw(this.activeElement))
                }
            },

            /**
             * 主流程增加、删除变量
             */
            async removeOrAddVar(val, item) {
                
                if (val === 0 || val) {
                    this.mainProcessVar.splice(val, 1);
                    this.updataMianProcess(item)
                } else {
                    // await this.checkVarComplete();
                    this.mainProcessVar.push({
                        id: this.randomString(),
                        type: '',
                        check: item?.code == 1 || item?.code == 2 ? true : false,
                        default: item?.code == 2 ? momemt().format('YYYY-MM-DD HH:MM:SS') : '',
                        desc: '',
                    })
                    
                }
            },

            /**
             * 主流程增变量更新
             * @param {Object} item 修改的变量对象
             * @param {String} type 修改的值类型 type == 'checkbox'选择框是否必填  type == false input输入框
             */
            updataMianProcess(item, type) {
                

                const typeInfo = dataTypes.find(ele => ele.type == item.type);

                item.check = type == 'checkbox' ? (item?.check ?? typeInfo?.check)  : item.check;
                
                item.default = item.check ? '' : item?.default ? item?.default : typeInfo?.code == 2 ? momemt().format('YYYY-MM-DD HH:MM:SS') : '';
                
                

                const { bpmnModeler, activeElement } = this;
                const moddle = bpmnModeler.get("moddle");
                // 原生扩展属性数组
                const extensions = moddle.create("bpmn:ExtensionElements", { values: [] });
                this.mainProcessVar.forEach(item => {
                    // 判断是否是分割线
                    if (item.type == 'splitLine') {
                        item = JSON.parse(JSON.stringify(item))
                        for(let key in item) {
                            item[key] = null
                        }
                    }
                    // root插入自定义属性
                    const attr = moddle.create("pvm:Parameter", { 
                        id: item.id,
                        type: item.type,
                        must: item.check,
                        default: item.default,
                        desc: item.desc,
                    })
                    extensions.values.push(attr);
                })
                this.updateProperties(toRaw(activeElement), {
                    extensionElements: extensions
                });
            },

            /**
             * 更新属性
             * @param {String | Object} obj1 右侧面板选中的值 val == 'pvm:definition' || 'pvm:right'时为Object
             * @param {String} obj2 参数的解析类型， 如：pvm:definition
             * @param {String} type 改变数据组件的类型
             * @param {Object} inOrOut 出入参信息
             */
            changeHandler(obj1, obj2, type, inOrOut) {
                
                if (type == 'class') {
                    this.changeField(obj1, obj2);
                    if (inOrOut) {
                        const inputs = inOrOut?.inputs ?? [];
                        const outputs = inOrOut?.outputs ?? [];
                        this.changeInputsOut(inputs, outputs);
                        this.updataElement = true
                    }
                } else if (type == 'table') {
                    this.changeInputsOut(obj1, obj2);
                } 
                // else if (type == 'SequenceFlow') {
                //     this.conditionExpression(obj1)
                // }
            },

            /**
             * 条件表达式更新
             */
            conditionExpression(val, element) {
                return new Promise((res) => {
                    const { bpmnModeler, activeElement } = this;
                    element = element || activeElement
                    const moddle = bpmnModeler.get("moddle");
                    let newCondition = moddle.create('bpmn:FormalExpression', {
                        body: val
                    });
                    res({conditionExpression: newCondition})
                })
                // this.updateProperties(toRaw(element), {
                //     conditionExpression: newCondition
                // });
            },

            
            /**
            * 改变控件触发的事件
            * @param { String } value 选中的值 
            * @param { String } type 要修改的属性的名称
            */
            async changeField(value, type,) {
                
                const { activeElement } = this;
                let properties = {};
                // 流程多增加一个方案的ID，在回显的时候使用
                if (type == 'plan:definition') {
                    properties[type] = value.modelId;
                    properties['plan:programmeid'] = value.id;
                } else if (type == 'pvm:right') { // 函数组件多增加一个name值，在回显的时候使用
                    properties[type] = value.right;
                    properties['pvm:subtype'] = value.name;
                } else if (type == 'name' && activeElement.type == 'bpmn:SequenceFlow') { // 条件表达式，同时更新name和条件表达式
                    const conditionBody = await this.conditionExpression(value);

                    properties = {
                        ...conditionBody
                    }
                    properties[type] = value;
                } else {
                    properties[type] = value;
                }
                
                this.updateProperties(toRaw(activeElement), properties)
            },
            
            /**
             * 入参出参更新
             */
            changeInputsOut(inputsTableList, outputsTableList) {
                const { bpmnModeler, activeElement } = this;
                const moddle = bpmnModeler.get("moddle");
                // 原生扩展属性数组
                const extensions = moddle.create("bpmn:ExtensionElements", { values: [] });

                inputsTableList.forEach(ele => {
                    const inputs = moddle.create("pvm:FromOutToInMapping", { 
                        from: ele.active,
                        to: ele.name,
                    })
                    
                    extensions.values.push(inputs);
                   
                })
                outputsTableList.forEach(ele => {
                    const outputs = moddle.create("pvm:FromInToOutMapping", { 
                        from: ele.name,
                        to: ele.active,
                    })
                    extensions.values.push(outputs);

                })
                
                this.updateProperties(toRaw(activeElement), {
                    extensionElements: extensions
                });

            },

            /**
             * 更新元素属性
             * @param { Object } 要更新的属性
             */
            updateProperties(element ,properties) {
                
                const { bpmnModeler } = this;
                const modeling = bpmnModeler.get('modeling')

                // modeling.removeElements([ element ]);
                modeling.updateProperties(toRaw(element), properties)
                if (this.updataElement) {
                    // const elementRegistry = bpmnModeler.get('elementRegistry');
                    // const temp = elementRegistry.get(element.id);
                    // this.activeElement = toRaw(temp)
                    // this.$nextTick(() => {
                    //     this.activeElement.type = type;
                    // })
                    this.updataElement = false;
                }
            },



            /**
             * 撤销
             */
            undo() {
                CommandStack && CommandStack.canUndo() && CommandStack.undo()
            },
            /**
             * 恢复
             */
            redo(){
                CommandStack && CommandStack.canRedo() && CommandStack.redo()
            },
            /**
             * 擦除重做
             */
            restart(){
                CommandStack && CommandStack.clear()
            },

            /**
             * 随机字符串
             */
            randomString(val = -6) {
                let type = 'BPM:Process';
                if (this.activeElement) {
                    type = this.activeElement.type
                }
                
                return `${Math.random().toString(36).slice(val)}_${type.split(':')[1].toLowerCase()}`;
            },
             
            
            /**
             * 流程节点元素属性展示条件
             */
            processAtt(val) {
                if (!this.activeElement) return false;
                const { type } = this.activeElement;

                if (!Array.isArray(val)) {
                    val = [val]
                }

                return val.includes(type);
            },

            /**
             * 保存是判断，所有变量是否都已wanshan
             */
             checkVarComplete() {
                return new Promise((res, rej) => {
                    
                    const temp = this.mainProcessVar.find(item => (!item.check && !item.default) || !item.type);
                    if (temp) {
                        const text = temp.type ? `请选择填写【${temp.type}】变量的默认值` : '请选择变量的类型';
                        this.$message.error(text)
                        return
                    }
                    res()
                })
            }

        }
    }
</script>

<style lang="less" scoped>
.custompPropertiesPanel {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 30%;
    height: 100%;
    padding-bottom: 40px;
    background: #fff;
    display: flex;
    flex-direction: column;
    background: #f7f7f8;
    border: solid 1px #b9bcc6;
    border-radius: 2px;
    box-sizing: border-box;
    overflow-y: auto;
    .custompPropertiesPanel_processName {
        margin: 0 10px;
        height: 60px;
        line-height: 60px;
        border-bottom: 1px solid #3c3c3c;
        font-size: 24px;
        font-weight: bold;
    }
    .custompPropertiesPanel_form {
        box-sizing: border-box;
        padding: 0 10px;
        .custompPropertiesPanel_form_table {
            .form_cell_lable {
                color: #606266;
                width: 80px;
                text-align: end;
                display: block;
                margin-bottom: 6px;
            }

            /deep/ .divider_table {
                margin: 20px 0;
                 .el-table__header-wrapper {
                    display: none;
                }
            }
            
            .emptyVar {
                text-align: center;
                margin: 50px;
                span {
                    cursor: pointer;
                    color: rgb(119, 142, 255);
                    border-bottom: 1px solid rgb(119, 142, 255);
                }
            }
            
            .form_table_divider, .form_table_divider1 {
                margin-bottom: 20px;
                .form_table_divider_cell {

                    height: 10px;
                    
                    background-color: rgb(119, 142, 255);
                }
            }
        }
    }
    /deep/ .el-table {
        .el-table--scrollable-x .el-table__body-wrapper {
            overflow-x: hidden;
        }
        .el-table__body-wrapper {
            .el-table__body {
                .el-table__row {
                    .el-input__inner {
                        background-color: transparent;
                    }
                    .el-table__cell {
                        padding: 0;
                        .table_cell_btn {
                            .removeOrAddVar {
                                margin-right: 4px;
                                cursor: pointer;
                                color: rgb(119, 142, 255);
                                border-bottom: 1px solid rgb(119, 142, 255);
                                &:last-child {
                                    margin-right: 0;
                                }
                            }
                        }
                        .cell {
                            padding: 0;
                        }
                    }
                }
                .divider_tr {
                    color: transparent;
                    &:hover {
                        cursor: pointer;
                        td {
                            background-color: rgb(119, 142, 255);
                        }
                    }
                    .el-table__cell {
                        border-right: none;
                        background-color: rgb(119, 142, 255);
                        .cell {
                            line-height: 10px;
                            height: 10px;
                            overflow: hidden;
                        }
                    }
                }
                .active-background-class {
                    background: rgba(119, 142, 255, 0.2);
                }
            }
        }
    }
    /deep/ .form_table_input {
        border: none;
        .el-input__inner {
            border: none;
            padding: 0 4px;
        }
        .el-textarea__inner {
            border: none;
            // resize: none;
            padding: 4px;
        }
    }
    /deep/ .form_cell_select {
        border: none;
        .el-input__inner {
            border: none;
            padding: 0 4px;
            padding-right: 20px;
        }
        .el-input__suffix {
            right: -2px;
        }
    }
    /deep/ .form_cell_checkbox {
        .el-checkbox__inner {
            width: 20px;
            height: 20px;
        }
        .el-checkbox__inner::after {
            height: 10px;
            left: 6px;
            width: 6px;
        }
    }
    /deep/ .el-form-item__content {
        line-height: inherit;
    }
}
</style>
