import PaletteProvider from './palette/PaletteProvider';
import NyanRenderer from './draw/NyanRenderer';

import ResizeAllRules  from './resize-all-rules/ResizeAllRules';


import ColorPicker  from './color-picker/ColorPicker';
import ColoredRenderer  from './color-picker/ColoredRenderer';
import ContextPadProvider  from './contextPad/ContextPadProvider';



// import nativeCopyPaste from './copyPaste//nativeCopyPaste';


export default {
    __init__: ['paletteProvider', 'nyanRenderer', 'resizeAllRules', 'contextPadProvider', 'bpmnCopyPaste', 'moddleCopy'],
    paletteProvider: ['type', PaletteProvider],
    nyanRenderer: ['type', NyanRenderer],
    resizeAllRules: ['type', ResizeAllRules],
    // colorPicker: ['type', ColorPicker],
    // coloredRenderer: ['type', ColoredRenderer],
    contextPadProvider: ['type', ContextPadProvider],
    // nativeCopyPaste: [ 'type', nativeCopyPaste ],
}


