
import {batchCreateCustom, shapeAction} from '../actionsList';

let toolActionList = [
    // {
    //     type: 'connect', // 连接线
    //     action: ['edit', 'bpmn-icon-connection-multi', '连接线', 'startConnect']
    // },
    // {
    //     type: 'replace', // 更改元素类型
    //     action: ['edit', 'bpmn-icon-screw-wrench', '更改元素类型', 'replaceElement']
    // },
    {
        type: 'delete', // 删除
        action: ['edit', 'bpmn-icon-trash', '删除', 'removeElement']
    },
    // {
    //     type: 'copy', // 拷贝
    //     action: ['copyPaste', 'bpmn-icon-copy', '拷贝', 'copyElement']
    // },
    // {
    //     type: 'paste', // 粘贴
    //     action: ['copyPaste', 'bpmn-icon-paste', '粘贴', 'pasteElement']
    // }
] 

export {
    batchCreateCustom, toolActionList, shapeAction
}