<template>
    <div class="inputOrOutput">
        <el-form :model="attrForm" :rules="attrRules" ref="attrForm" label-width="80px">
            <el-form-item label="名称" prop="name">
                <el-input
                    @input="changeField($event, 'name')"
                    v-model="attrForm.name" 
                    :disabled="notEdit || (activeElement && activeElement.type == 'bpmn:SequenceFlow')" 
                    placeholder="请输入名称">
                </el-input>
            </el-form-item>
            <el-form-item label="标识" prop="id">
                <el-input 
                    @change="changeField($event, 'id')"
                    @input="inputHandler"
                    v-model="attrForm.id"
                    :disabled="notEdit" 
                    placeholder="请输入标识">
                </el-input>
            </el-form-item>
            
            <el-form-item label="任务简要" prop="slogan" v-if="activeElement && ['bpmn:Task'].includes(activeElement.type)">
                <el-input 
                    @change="changeField($event, 'slogan')"
                    v-model="attrForm.slogan" 
                    :disabled="notEdit" 
                    placeholder="请输入任务简要">
                </el-input>
            </el-form-item>

            <el-form-item 
                label="表达式" 
                prop="conditionExpression"
                v-if="activeElement && activeElement.type == 'bpmn:SequenceFlow'">
                <el-input 
                    v-model="attrForm.name"
                    @change="changeField($event, 'name')"
                    type="textarea"
                    :rows="2" 
                    :disabled="notEdit" 
                    placeholder="请输入表达式">
                </el-input>
            </el-form-item>
            <el-form-item 
                :label="lableName" 
                prop="taskType"
                v-if="activeElement && ['bpmn:ScriptTask', 'bpmn:Task'].includes(activeElement.type)">
                <el-select 
                    @change="changeField($event, `${activeElement.type == 'bpmn:ScriptTask' ? 'pvm:type' : 'plan:class'}`, true)"
                    v-model="attrForm.taskType" 
                    :disabled="notEdit" 
                    :placeholder="`请选择${lableName}`">
                    <el-option 
                        v-for="(item, index) in inOutList"
                        :key="`${item.type}_${index}`"
                        :label="item.type" 
                        :value="item.type">
                    </el-option>
                </el-select>
            </el-form-item>
            
            <el-form-item 
                label="子类型" 
                prop="subtype" 
                v-if="activeElement && activeElement.type == 'bpmn:ScriptTask'">
                <el-select 
                    @change="changeField($event, 'pvm:right', true)"
                    v-model="attrForm.subtype" 
                    :disabled="notEdit" 
                    :placeholder="`请选择子类型`">
                    <el-option 
                        v-for="(item, index) in subtypeList"
                        :key="`${item.name}_${index}`"
                        :label="item.name"
                        :value="item.name">
                    </el-option>
                </el-select>
            </el-form-item>
            
            
            <el-form-item 
                label="流程" 
                prop="taskType" 
                v-if="activeElement && activeElement.type == 'bpmn:SubProcess'">
                
                <el-select
                    v-model="attrForm.taskType"
                    filterable
                    remote
                    :disabled="notEdit"
                    value-key="id"
                    placeholder="请输入流程"
                    @focus="getProgramme('')"
                    :remote-method="getProgramme"
                    :loading="getProgrammeLoading"
                    @change="changeField($event, 'plan:definition', true)">
                    <el-option
                        v-for="item in subProcessList"
                        :key="item.id"
                        :label="item.name"
                        :value="item">
                    </el-option>
                </el-select>
            </el-form-item>
            
            <el-form-item 
                v-if="inputsTableList.length"
                label="入参" 
                class="inputOrOutput_param">
                <meta-data-table 
                    type="input"
                    :notEdit="notEdit"
                    :elementType="activeElement.type"
                    :metaList="inputsTableList"
                    @change="changeInputsOut($event, 'IN')">
                </meta-data-table>
            </el-form-item>
            
            <el-form-item 
                v-if="outputsTableList.length"
                label="出参"  
                class="inputOrOutput_param">
                <meta-data-table
                    type="output"
                    :notEdit="notEdit"
                    :elementType="activeElement.type"
                    :metaList="outputsTableList"
                    @change="changeInputsOut($event, 'OUT')">
                </meta-data-table>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
// import metaDataTable from './metaDataTable.vue'; // 表格组件
import { set, toRaw } from 'vue';
import $http from '@/service/index';
import {shapeAction} from '../actionsList'; // 图形变量
export default {
    name: 'inputOrOutput',
    components: {
        metaDataTable: () => import('./metaDataTable.vue')
    },
    props: {
        // notEdit == true 不可以编辑
        notEdit: {
            type: Boolean,
            default: true, 
        },
        // 当前选中的元素
        activeElement: {
            type: Object,
            default: () => null
        },

        // 没有其他含义，只用于计算属性
        updataElement: {
            type: Boolean,
            default: false
        }
    }, 
    inject: ['topThis'],
    computed: {
        //lable名称
        lableName() {
            if (!this.activeElement) return;
            const { type } = this.activeElement;
            let temp = '';
            switch(type) {
                case 'bpmn:ScriptTask':
                    temp = '类型'
                    break;
                
                case 'bpmn:Task':
                    temp = '类型'
                    break;
                
                case 'bpmn:SubProcess':
                    temp = '流程'
                    break;
            }
            return temp;
        },

        // 子类型列表数据
        subtypeList() {
            let temp = this.inOutList.find(item => item.type == this.attrForm.taskType);
            const list = temp?.sub ?? [];
            list.forEach(item => {
                item?.active ?? (item.active = '');
                
            })
            return list;
        },

        // 入参参数表格数据
        inputsTableList() {
            const updataElement = this.updataElement
            
            if (!this.activeElement) return [];
            const { type } = this.activeElement;
            let temp;
            if (type == 'bpmn:ScriptTask') {
              temp = this.subtypeList.find(item => item.name == this.attrForm.subtype)?.inputs ?? [];
            } 
            else if (type == 'bpmn:SubProcess') {
              temp = JSON.parse(JSON.stringify(this.subProcessParams));
            }
            else {
              temp = this.inOutList.find(item => item.type == this.attrForm.taskType)?.inputs ?? [];
            }
            const list = temp ?? [];

           
           
            return this.updateOutIntput(list, 'in');
        },

        // 出参参数表格数据
        outputsTableList() {
            const updataElement = this.updataElement
            if (!this.activeElement) return [];
            const { type } = this.activeElement;
            let temp;
            if (type == 'bpmn:ScriptTask') {
              temp = this.subtypeList.find(item => item.name == this.attrForm.subtype)?.outputs ?? [];
            } else if (type == 'bpmn:SubProcess') {
              temp = JSON.parse(JSON.stringify(this.subProcessParams));
            } else {
              temp = this.inOutList.find(item => item.type == this.attrForm.taskType)?.outputs ?? [];
            }
            const list = temp ?? [];
            
            return this.updateOutIntput(list);
        },

    },
    watch: {
        // 监听选中元素的改变
        activeElement: {
            async handler(newVal, oldVal) {
                if (!newVal) return;
               
                this.oldActiveElement = toRaw(oldVal);
                // 更新属性面板数据
                this.updateArrData();
                this.attrRules.taskType = [{ required: true, message: `请选择${this.lableName}`, trigger: 'change' }]
                const bpmnType = newVal.type.split(":")[1];
                if (bpmnType) {
                    // 获取出入参数据信息
                    const type = bpmnType.toLowerCase();
                    await this.getMetaData(type);

                }
               
            },
            deep: true
        },

        // 监听任务组件类型改变，更新名称文案
        'attrForm.taskType'(val, oldVal) {
            if (this.activeElement.type != 'bpmn:Task' || !val || this.notEdit) return;

            

            const temp = this.inOutList.find(item => item.type == this.attrForm.taskType);

            let taskName = '普通任务';
            const taskShape = shapeAction.filter(item => item.type == 'create.task');
            const actionNum = taskShape?.[0]?.action?.length ?? 0;
            taskName = taskShape?.[0]?.action?.[actionNum] ?? taskName;

            const name = this.activeElement.businessObject.name;
            const slogan = this.activeElement.businessObject.slogan;
            const isChangeType = this?.activeElement?.id != this.attrForm.changeId;
            
            this.attrForm['changeId'] = this?.activeElement?.id; // 用于任务组件类型改变时使用
            if ( ((!name || name == taskName) && isChangeType) || !isChangeType) {
                this.attrForm.name = temp?.name || '';
                this.$emit('change', this.attrForm.name, 'name', 'class');

            }
            
            if ((!slogan && isChangeType) || !isChangeType) {
                this.attrForm.slogan = temp?.slogan || '';
                this.$emit('change', this.attrForm.slogan, 'slogan', 'class');
            }
            
        }
    },
    data() {
        return {
            
            attrForm: { // 流程属性值
                id: '', // 流程ID标识
                name: '', // 流程name
                taskType: '', // 任务类型
                subtype: '', // 子类型类型
                slogan: '', // 任务简要
                // conditionExpression: '', // 条件表达式
            },
            attrRules: {
                name: [ { required: true, message: '请输入名称', trigger: 'blur' }],
                id: [ { required: true, message: '请输入标识', trigger: 'blur' }],
                // conditionExpression: [ { required: true, message: '请输入表达式', trigger: 'blur' }],
                taskType: [{ required: true, message: `请选择类型`, trigger: 'change' }],
                subtype: [{ required: true, message: `请选择子类型`, trigger: 'change' }],
                // slogan: [ { required: true, message: '请输入任务简要', trigger: 'blur' }],
            },
            subProcessList: [], // 子流程
            getProgrammeLoading: false, // 获取子流程数据时的loding
            subProcessParams: [], // 子流程变量参数
            inOutList: [], // 出入参数据
            oldActiveElement: null, // 上一次选中的图形
            
            outputsTableTemp: [], // 出参缓存
            inputsTableTemp: [], // 入参缓存
        }
    },
    methods: {


        /**
         * 获取出入参数据信息
         * @param {Sting} type 元素类型
         */
        async getMetaData(type) {
            return new Promise( async (res) => {

                let temp = this.$store.state.metaData;
                const inOutInfo = temp.find(item => item.type == type)
                if (inOutInfo?.params) {
                    this.inOutList = inOutInfo.params;
                    res(this.inOutList);
                    return
                }
                
                const params = {
                    data: {
                        type: type
                    },
                    config: {
                        target: ".custompPropertiesPanel",
                        showLoading: true,
                        text: '加载参数信息',
                    }
                };
                const info = await $http.getMetaData(params);
                if (info.code == '000000') {
                    this.inOutList = info?.result ?? [];
                    temp.push({
                        type: type,
                        params: this.inOutList
                    })
                    this.$store.commit('setMetaData', temp);
                    res(this.inOutList)
                }
            });
        },

        /**
         * 标识符禁止输入中文
         */
        inputHandler(val) {
            this.attrForm.id = val.replace(/[^\x00-\xff]/g, '')
        },

        /**
         * 监听表格参数更改
         */
        changeInputsOut(list, type) {
            
            // 缓存出入参信息，解决入参更新后直接更新出参时，入参保存不上的问题
            if (type == 'IN') {
                this.inputsTableTemp = list;
            } else {
                this.outputsTableTemp = list;
            }
            
            let inList = type === 'IN' ? list : this.inputsTableTemp;
            let outList = type === 'OUT' ? list : this.outputsTableTemp;

            this.$emit('change', inList, outList, 'table');
        },

        /**
         * 监听参数类型改变
         * @param {String | Object} type val == 'pvm:definition'时为Object
         * @param {String} val Bpmn增加参数类型
         * @param {Boolean} param true == 返回出入参信息， false == 不返回
         */
        async changeField(type, val, param) {
            
            this.oldActiveElement = JSON.parse(JSON.stringify(this.oldActiveElement));
            let values = [...(this?.activeElement?.businessObject?.extensionElements?.values ?? [])];

            
            // 子类型
            if (val == 'pvm:right') {
                const info = this.subtypeList.find(item => item.name == type) ?? {}
                type = {
                    right: info?.right ?? '',
                    name: info?.name ?? ''
                }
            }
            // 流程
            if (val == 'plan:definition') {
                await this.getProgrammeDetails(type.id);
                this.oldActiveElement['values'] = values
            }
            // 更新出入参信息
            if (param) {
                let inputs = JSON.parse(JSON.stringify(this.inputsTableList));
                let outputs = JSON.parse(JSON.stringify(this.outputsTableList));
                if (val != 'plan:definition') {
                    inputs.forEach(item => {
                        item.active = ''
                    })
                    outputs.forEach(item => {
                        item.active = ''
                    })
                }
                this.$emit('change', type, val, 'class', {inputs, outputs});
            } else {
                this.$emit('change', type, val, 'class');
            }
            
        },

        /**
         * 监听表达式改变
         */
        conditionExpression(val) {
            this.$emit('change', val, null, 'SequenceFlow');
        },

        /**
         * 更新属性面板数据
         */
        updateArrData() {
            if (!this.activeElement) {
                this.attrForm.id = this.topThis.processId;
                this.attrForm.name = this.topThis.processName;
                return
            };
            let tempType = this.activeElement?.businessObject?.class ?? '';
            if (this.activeElement.type == 'bpmn:ScriptTask') {
                tempType = this.activeElement?.businessObject?.type ?? '';
            }


            this.attrForm = { // 解决改变taskType更新不执行监听问题
                ...this.attrForm,
                taskType: null,
            }

            this.$nextTick(() => {

                this.attrForm = {
                    ...this.attrForm,
                    ...toRaw(this.activeElement),
                    changeId: '', // 用于任务组件类型改变时使用
                    taskType: tempType, // 任务类型
                    subtype: this.activeElement?.businessObject?.subtype, // 子类型类型
                    name: this.activeElement?.businessObject?.name ?? '',
                    slogan: this.activeElement?.businessObject?.slogan ?? '',
                    // conditionExpression: this.activeElement?.businessObject?.conditionExpression?.body ?? ''
                }
    
                
                if (!this.attrForm.name) {
                    this.attrForm.name = this.topThis.getProcessName
                }
                if (this.activeElement.type == 'bpmn:SubProcess' && this.activeElement?.businessObject?.programmeid) {
                    this.getProgramme(null, null, this.activeElement?.businessObject?.programmeid, true);
                }
            })
            
        },

        /**
         * 切换节点，更新属性面板出入参数据
         */
        updateOutIntput(list, type) {
             // 缓存出入参信息，解决入参更新后直接更新出参时，入参保存不上的问题
            //  debugger
             if (type == 'in') {
                this.inputsTableTemp = list;
            } else {
                this.outputsTableTemp = list;
            }
            let values = this?.activeElement?.businessObject?.extensionElements?.values ?? [];
            list = list.filter(item => !item.id);
            values = values.filter(item => item.from && item.to);

            list.forEach(sonIn => {
                if (values.length) {

                    const temp = values.find(ele => (type == 'in' && ele.$type == 'pvm:FromOutToInMapping' ? ele.to : !type && ele.$type == 'pvm:FromInToOutMapping' ? ele.from : '') == sonIn.name);
                    if (!temp) {
                        this.$set(sonIn, 'active', '')
                    }
                } else {
                    this.$set(sonIn, 'active', '')
                }
            });
            values.forEach((item, index, arr) => {
                
                // 入参
                if (type == 'in' && item.$type == 'pvm:FromOutToInMapping') {
                    const temp = list.find(ele => ele.name == item.to);
                    if (temp) {
                        this.$set(temp, 'active', item.from)
                    } else {
                        list.push({
                            "name": "",
                            "desc": null,
                            "id": Date.now(), 
                            "must": false,
                            "className": "",
                            "defaultValue": null,
                            "active": item.from
                        })
                    }
                } else if (!type && item.$type == 'pvm:FromInToOutMapping') { // 出参

                    const temp = list.find(ele => ele.name == item.from);
                    if (temp) {
                        this.$set(temp, 'active', item.to)
                    } else {
                        list.push({
                            "name": "",
                            "id": Date.now(), 
                            "desc": null,
                            "must": false,
                            "className": "",
                            "defaultValue": null,
                            "active": item.to
                        })
                    }
                }
            })

            if (this.oldActiveElement?.values) {

                values = this.oldActiveElement?.values;
                values = values.filter(item => item.from && item.to);
                values.forEach((item, index, arr) => {
                    
                    // 入参
                    if (type == 'in' && item.$type == 'pvm:FromOutToInMapping') {
                        const temp = list.find(ele => ele.name == item.to);
                        if (temp) {
                            this.$set(temp, 'active', item.from)
                        }
                    } else if (!type && item.$type == 'pvm:FromInToOutMapping') { // 出参

                        const temp = list.find(ele => ele.name == item.from);
                        if (temp) {
                            this.$set(temp, 'active', item.to)
                        }
                    }
                })
                
                delete this.oldActiveElement.values
            }

            // list.forEach(item => {
            //     if (values.length) {

            //         const temp = values.find(ele => (type == 'in' && ele.$type == 'pvm:FromOutToInMapping' ? ele.to : !type && ele.$type == 'pvm:FromInToOutMapping' ? ele.from : '') == item.name);
            //         if (temp) {
            //             this.$set(item, 'active', type == 'in' ? temp.from : temp.to)
            //         } else {
            //             this.$set(item, 'active', '')
            //         }
            //     } else {
            //         this.$set(item, 'active', '')
            //     }
            // })
            return JSON.parse(JSON.stringify(list));
        },

         /**
         * 获取方案列表数据
         * @param {String} name 方案名称
         * @param {Function} cb 远程搜索回调
         * @param {String} id 获取方案的id
         * @param {Boolean} val 编辑查看是获取流程信息并回显
         */
        async getProgramme(name, cb, id, val) {
            

            const params = {
                name: name || '',
                status: '1'
            }
            if (id) {
                params.id = id;

            }
            
            this.getProgrammeLoading = true;
            const res = await $http.getProgramme(params);
            
            this.getProgrammeLoading = false;
            if (res.code == '000000') {
                this.subProcessList = res?.result?.result ?? [];
                // 
                if (val) {
                    const taskType =  this.subProcessList.find(item => item.id == id) ?? '';
                    this.$set(this.attrForm, "taskType", taskType);
                    this.getProgrammeDetails(taskType.id);
                }
                
            }
        },

        /**
         * 获取方案详情
         * @param {String} id 获取方案详情的id
         */
         async getProgrammeDetails(id) {
            const params = {
                data: {
                    id: id,
                    needParams: true,
                },
                config: {
                    target: '.custompPropertiesPanel',
                    showLoading: true,
                    text: '加载中...',
                }
            };
            const varInfo = await $http.getProgrammeDetails(params);
            return new Promise((res, rej) => {

                if(varInfo.code == '000000') {
                    let temp = varInfo?.result?.params ?? [];
                    temp = temp.reduce((pre, cur) => {
                        const attr = Object.values(cur).every(o => !o);
                        if (!attr) {
                            pre.push(cur)
                        }
                        return pre
                    }, [])
                    this.subProcessParams = temp;
                    res()
                } else {
                    this.subProcessParams = [];
                    rej()
                }
            });
        },




    }
}
</script>

<style lang="less" scoped>
.inputOrOutput {
    padding: 10px;
    /deep/ .inputOrOutput_param {
        .el-form-item__label {
            float: none;
            display: block;
        }
        .el-form-item__content {
            margin-left: 0 !important;
        }
    }

}
</style>