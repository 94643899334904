

const toolAction = [ // 工具
    {
        type: 'hand-tool', // 抓手
        action: ['bpmn-icon-hand-tool', '抓手', 'handTool']
    },
    {
        type: 'lasso-tool', // 套索
        action: ['bpmn-icon-lasso-tool', '套索', 'lassoTool']
    },
    {
        type: 'space-tool', // 空间
        action: ['bpmn-icon-space-tool', '空间', 'spaceTool']
    },
];

const shapeAction = [ // 图形
    {
        type: 'create.start-event',
        action: ['bpmn:StartEvent', 'event', 'bpmn-icon-start-event-none', '开始节点'],
    },
    {
        type: 'create.end-event',
        action: ['bpmn:EndEvent', 'event', 'bpmn-icon-end-event-none', '结束节点']
    },
    {
        type: 'global-connect-tool',
        action: ['bpmn:SequenceFlow', 'activity', 'bpmn-icon-connection-multi', '']
    },
    {
        type: 'create.task',
        action: ['bpmn:Task', 'activity', 'bpmn-icon-task', '普通任务']
    },
    {
        type: 'create.ScriptTask',
        action: ['bpmn:ScriptTask', 'activity', 'bpmn-icon-script-task', '函数']
    },
    {
        type: 'create.exclusive-gateway',
        action: ['bpmn:ExclusiveGateway', 'activity', 'bpmn-icon-gateway-none', '网关']
    },
    {
        type: 'create.subprocess-expanded',
        action: ['bpmn:SubProcess', 'activity', 'bpmn-icon-subprocess-expanded', '子流程']
    }
];


/**
* 循环创建出一系列的元素
* @param {Array} actions 元素集合
* @param {Object} fn 处理的函数
*/
function batchCreateCustom(actions, fn) {
    const customs = {}
    actions.forEach(item => {
        
        customs[item['type']] = fn(...item['action'])
    })
    return customs
}

export {
    batchCreateCustom,
    toolAction,
    shapeAction

}
