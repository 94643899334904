import {
  assign,
  forEach,
  isArray,
  every
} from 'min-dash';


import {
  isAny
} from 'bpmn-js/lib/features/modeling/util/ModelingUtil' //'../modeling/util/ModelingUtil';

import {
  hasPrimaryModifier
} from 'diagram-js/lib/util/Mouse';

import {batchCreateCustom, toolActionList, shapeAction} from './customConfig';


/**
 * @typedef {import('didi').Injector} Injector
 * @typedef {import('diagram-js/lib/core/EventBus').default} EventBus
 * @typedef {import('diagram-js/lib/features/context-pad/ContextPad').default} ContextPad
 * @typedef {import('../modeling/Modeling').default} Modeling
 * @typedef {import('../modeling/ElementFactory').default} ElementFactory
 * @typedef {import('diagram-js/lib/features/connect/Connect').default} Connect
 * @typedef {import('diagram-js/lib/features/create/Create').default} Create
 * @typedef {import('diagram-js/lib/features/popup-menu/PopupMenu').default} PopupMenu
 * @typedef {import('diagram-js/lib/features/canvas/Canvas').default} Canvas
 * @typedef {import('diagram-js/lib/features/rules/Rules').default} Rules
 * @typedef {import('diagram-js/lib/i18n/translate/translate').default} Translate
 *
 * @typedef {import('../../model/Types').Element} Element
 * @typedef {import('../../model/Types').ModdleElement} ModdleElement
 *
 * @typedef {import('diagram-js/lib/features/context-pad/ContextPadProvider').default<Element>} BaseContextPadProvider
 * @typedef {import('diagram-js/lib/features/context-pad/ContextPadProvider').ContextPadEntries} ContextPadEntries
 * @typedef {import('diagram-js/lib/features/context-pad/ContextPadProvider').ContextPadEntry} ContextPadEntry
 *
 * @typedef { { autoPlace?: boolean; } } ContextPadConfig
 */

/**
 * BPMN-specific context pad provider.
 *
 * @implements {BaseContextPadProvider}
 *
 * @param {ContextPadConfig} config
 * @param {Injector} injector
 * @param {EventBus} eventBus
 * @param {ContextPad} contextPad
 * @param {Modeling} modeling
 * @param {ElementFactory} elementFactory
 * @param {Connect} connect
 * @param {Create} create
 * @param {PopupMenu} popupMenu
 * @param {Canvas} canvas
 * @param {Rules} rules
 * @param {Translate} translate
 */
export default function ContextPadProvider(
    config, injector, eventBus,
    contextPad, modeling, elementFactory,
    connect, create, popupMenu,
    canvas, rules, translate) {

  config = config || {};

  contextPad.registerProvider(this);

  this._contextPad = contextPad;

  this._modeling = modeling;

  this._elementFactory = elementFactory;
  this._connect = connect;
  this._create = create;
  this._popupMenu = popupMenu;
  this._canvas = canvas;
  this._rules = rules;
  this._translate = translate;

  if (config.autoPlace !== false) {
    this._autoPlace = injector.get('autoPlace', false);
  }
  eventBus.on('create.end', 250, function(event) {
    var context = event.context,
        shape = context.shape;

    if (!hasPrimaryModifier(event) || !contextPad.isOpen(shape)) {
      return;
    }

    var entries = contextPad.getEntries(shape);

    if (entries.replace) {
      entries.replace.action.click(event, shape);
    }
  });
}

ContextPadProvider.$inject = [
  'config.contextPad',
  'injector',
  'eventBus',
  'contextPad',
  'modeling',
  'elementFactory',
  'connect',
  'create',
  'popupMenu',
  'canvas',
  'rules',
  'translate'
];

/**
 * @param {Element[]} elements
 *
 * @return {ContextPadEntries}
 */
ContextPadProvider.prototype.getMultiElementContextPadEntries = function(elements) {
  var modeling = this._modeling;

  var actions = {};
  if (this._isDeleteAllowed(elements)) {
    assign(actions, {
      'delete': {
        group: 'edit',
        className: 'bpmn-icon-trash',
        title: this._translate('Remove'),
        action: {
          click: function(event, elements) {
            modeling.removeElements(elements.slice());
          }
        }
      }
    });
  }

   // 创建工具类型元素
  //  function createToolAction(group, className, title, fnName) {
    
  //   return {
  //     group: group || 'tools',
  //     className,
  //     title,
  //     action: {
  //       click: function(event, elements) {
  //         debugger
  //         switch(fnName) {
  //           case 'removeElement':
  //             modeling.removeElements(elements.slice());
  //             break;
  //           case 'copyElement':
  //             copyElement(event, elements)
  //             break;
  //           case 'pasteElement':
  //             pasteElement(event, elements)
  //             break;
  //         }
  //       },
  //     }
  //   }
  // }
  //   // copy元素
  //   function copyElement(event, element) {
  //     // debugger
  //   }
  //   // 粘贴元素
  //   function pasteElement(event, element) {
  //     // debugger
  //   }
  // // 检查是否选择了多个元素
  // if (elements.length > 1) {
  
  //   // 添加自定义操作
  //   assign(actions, {
  //     ...batchCreateCustom(toolActionList, createToolAction), // 工具
  //   });
  // }

  return actions;
};

/**
 * @param {Element[]} elements
 *
 * @return {boolean}
 */
ContextPadProvider.prototype._isDeleteAllowed = function(elements) {

  var baseAllowed = this._rules.allowed('elements.delete', {
    elements: elements
  });

  if (isArray(baseAllowed)) {
    return every(baseAllowed, function(element) {
      return includes(baseAllowed, element);
    });
  }

  return baseAllowed;
};

/**
 * @param {Element} element
 *
 * @return {ContextPadEntries}
 */
ContextPadProvider.prototype.getContextPadEntries = function(element) {
  var contextPad = this._contextPad,
      modeling = this._modeling,

      elementFactory = this._elementFactory,
      connect = this._connect,
      create = this._create,
      popupMenu = this._popupMenu,
      rules = this._rules,
      autoPlace = this._autoPlace,
      translate = this._translate;

  var actions = {};
  if (element.type === 'label') {
    return actions;
  }

  var businessObject = element.businessObject;

  // 创建工具类型元素
  function createToolAction(group, className, title, fnName) {
    
    return {
      group: group || 'tools',
      className,
      title,
      action: {
        click: function(event, element) {
          
          switch(fnName) {
            case 'startConnect':
              
              startConnect(event, element)
              break;
            case 'replaceElement':
              replaceElement(event, element)
              break;
            case 'removeElement':
              removeElement(event, element)
              break;
          }
        },
      }
    }
  }

  


  // 连接线
  function startConnect(event, element) {
    connect.start(event, element);
  }

  // 删除元素
  function removeElement(e, element) {
    
    modeling.removeElements([ element ]);
  }

  // 更改元素类型
  function replaceElement(event, element) {
    var position = assign(getReplaceMenuPosition(element), {
      cursor: { x: event.x, y: event.y }
    });

    popupMenu.open(element, 'bpmn-replace', position, {
      title: translate('Change element'),
      width: 300,
      search: true
    });
  }

  // 更改元素类型参数处理
  function getReplaceMenuPosition(element) {

    var Y_OFFSET = 5;

    var pad = contextPad.getPad(element).html;

    var padRect = pad.getBoundingClientRect();

    var pos = {
      x: padRect.left,
      y: padRect.bottom + Y_OFFSET
    };

    return pos;
  }

  /**
   * 创建追加操作元素
   *
   * @param {string} type
   * @param {string} group
   * @param {string} className
   * @param {string} [title]
   * @param {Object} [options]
   *
   * @return {ContextPadEntry}
   */
  function appendAction(type, group, className, title, options) {
    
    if (typeof title !== 'string') {
      options = title;
      title = translate('Append {type}', { type: type.replace(/^bpmn:/, '') });
    }
    /**
     * 创建子流程
     */
    function createSubprocess(event, element, val) {
      
      var subProcess = elementFactory.createShape({
        type: 'bpmn:SubProcess',
        x: 0,
        y: 0,
        isExpanded: true
      });
      
      // var startEvent = elementFactory.createShape({
      //   type: 'bpmn:StartEvent',
      //   x: 40,
      //   y: 82,
      //   parent: subProcess
      // });
      
      if (val) {
        autoPlace.append(element, subProcess);
      } else {
        create.start(event, subProcess, {
          source: element
        });
      }
    }
    function appendStart(event, element) {
      if (type == 'bpmn:SubProcess') {
        createSubprocess(event, element)
        return
      } else if (type == 'bpmn:SequenceFlow') {
        connect.start(event, element);
      } else {
        var shape = elementFactory.createShape(assign({ type: type }, options));
        create.start(event, shape, {
          source: element
        });
      }
      
    }


    var append = autoPlace ? function(event, element) {
      
      if (type == 'bpmn:SubProcess') {
        createSubprocess(event, element, true)
        
      }  else if (type == 'bpmn:SequenceFlow') {
        connect.start(event, element);
      } else {
        var shape = elementFactory.createShape(assign({ type: type }, options));
        
        if (title && !['bpmn:StartEvent', 'bpmn:EndEvent', 'bpmn:ExclusiveGateway', 'bpmn:SequenceFlow'].includes(type)) {
          modeling.updateProperties(shape, {name: title})
        }
        autoPlace.append(element, shape);
      }

    } : appendStart;


    return {
      group: group || 'model',
      className: className,
      title: title,
      action: {
        dragstart: appendStart,
        click: append
      }
    };
  }

  // 只有点击元素才可以创建追加操作元素
  if(isAny(businessObject, [
    'bpmn:StartEvent',
    'bpmn:Task',
    'bpmn:ScriptTask',
    'bpmn:DataObjectReference',
    'bpmn:SubProcess',
    'bpmn:ExclusiveGateway'
  ])) {
    assign(actions, {
      ...batchCreateCustom(shapeAction, appendAction), // 图形
      ...batchCreateCustom(toolActionList, createToolAction), // 工具
    });
  }
  // 结束节点和线只有删除和编辑
  if(isAny(businessObject, [
    'bpmn:EndEvent',
    'bpmn:SequenceFlow'
  ])) {

    assign(actions, {
      // ...batchCreateCustom(shapeAction, appendAction), // 图形
      ...batchCreateCustom(toolActionList, createToolAction), // 工具
    });
  }

  return actions;
};


// helpers /////////

/**
 * @param {ModdleElement} businessObject 
 * @param {string} type
 * @param {string} eventDefinitionType
 *
 * @return {boolean}
 */
function isEventType(businessObject, type, eventDefinitionType) {

  var isType = businessObject.$instanceOf(type);
  var isDefinition = false;

  var definitions = businessObject.eventDefinitions || [];
  forEach(definitions, function(def) {
    if (def.$type === eventDefinitionType) {
      isDefinition = true;
    }
  });

  return isType && isDefinition;
}

function includes(array, item) {
  return array.indexOf(item) !== -1;
}