import {
    assign
  } from 'min-dash';
  
  import { getDi } from 'bpmn-js/lib/util/ModelUtil';
  import {batchCreateCustom, toolAction, shapeAction} from '../actionsList';
  
  /**
   * @typedef {import('diagram-js/lib/features/palette/Palette').default} Palette
   * @typedef {import('diagram-js/lib/features/create/Create').default} Create
   * @typedef {import('diagram-js/lib/core/ElementFactory').default} ElementFactory
   * @typedef {import('../space-tool/BpmnSpaceTool').default} SpaceTool
   * @typedef {import('diagram-js/lib/features/lasso-tool/LassoTool').default} LassoTool
   * @typedef {import('diagram-js/lib/features/hand-tool/HandTool').default} HandTool
   * @typedef {import('diagram-js/lib/features/global-connect/GlobalConnect').default} GlobalConnect
   * @typedef {import('diagram-js/lib/i18n/translate/translate').default} Translate
   *
   * @typedef {import('diagram-js/lib/features/palette/Palette').PaletteEntries} PaletteEntries
   */
  
  /**
   * A palette provider for BPMN 2.0 elements.
   *
   * @param {Palette} palette
   * @param {Create} create
   * @param {ElementFactory} elementFactory
   * @param {SpaceTool} spaceTool
   * @param {LassoTool} lassoTool
   * @param {HandTool} handTool
   * @param {GlobalConnect} globalConnect
   * @param {Translate} translate
   */
  export default function PaletteProvider(
      palette, create, elementFactory,
      spaceTool, lassoTool, handTool,
      globalConnect, translate) {
  
    this._palette = palette;
    this._create = create;
    this._elementFactory = elementFactory;
    this._spaceTool = spaceTool;
    this._lassoTool = lassoTool;
    this._handTool = handTool;
    this._globalConnect = globalConnect;
    this._translate = translate;
  
    palette.registerProvider(this);
  }
  
  PaletteProvider.$inject = [
    'palette',
    'create',
    'elementFactory',
    'spaceTool',
    'lassoTool',
    'handTool',
    'globalConnect',
    'translate'
  ];
  
  /**
   * @return {PaletteEntries}
   */
  PaletteProvider.prototype.getPaletteEntries = function() {
  
    var actions = {},
        create = this._create,
        elementFactory = this._elementFactory,
        spaceTool = this._spaceTool,
        lassoTool = this._lassoTool,
        handTool = this._handTool,
        globalConnect = this._globalConnect,
        translate = this._translate;
    
    /**
     * 创建图形
     */
    function createAction(type, group, className, title, options) {

      function createListener(event) {
        if (type == 'bpmn:SubProcess') {
          createSubprocess(event)
        } else if (type == 'bpmn:SequenceFlow') {
          globalConnect.start(event)
        } else {
          var shape = elementFactory.createShape(assign({ type: type }, options));
          
          if (options) {
            var di = getDi(shape);
            di.isExpanded = options.isExpanded;
          }
          create.start(event, shape);

        }
      }
  
      var shortType = type.replace(/^bpmn:/, '');
  
      return {
        group: group,
        className: className,
        title: `新增${title}` || translate('Create {type}', { type: shortType }),
        action: {
          dragstart: createListener,
          click: createListener,
        }
      };
    }

    /**
     * 创建子流程
     */
    function createSubprocess(event) {

      var subProcess = elementFactory.createShape({
        type: 'bpmn:SubProcess',
        x: 0,
        y: 0,
        isExpanded: true
      });
      
      // var startEvent = elementFactory.createShape({
      //   type: 'bpmn:StartEvent',
      //   x: 40,
      //   y: 82,
      //   parent: subProcess
      // });
  
      create.start(event, [ subProcess ], {
        hints: {
          autoSelect: [ subProcess ]
        }
      });
    }

    /**
     * 创建工具
     */
    function createToolAction( className, title, fnName) {
      return {
          group: 'tools',
          className,
          title: title,
          action: {
              click: function(event) {
                switch(fnName) {
                  case 'handTool':
                    handTool.activateHand(event)
                    break;
                  case 'lassoTool':
                    lassoTool.activateSelection(event)
                    break;
                  case 'spaceTool':
                    spaceTool.activateSelection(event)
                    break;
                  case 'globalConnect':
                    globalConnect.start(event)
                    break;
                }
              }
          }
      }
    }
    // [handTool.activateHand, lassoTool.activateSelection, spaceTool.activateSelection, globalConnect.start]
  
  
    assign(actions, {
      ...batchCreateCustom(toolAction, createToolAction), // 工具
      'tool-separator': {
        group: 'tools',
        separator: true
      },
      ...batchCreateCustom(shapeAction, createAction), // 图形
    });
  
    return actions;
  };
  