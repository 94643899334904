<template>
	<div class="bpmn" :class="{'bpmnView': notEdit}" @click="menuShow = false">
		<div class="bpmn_content">

			<div class="bpmn_canvas" id="bpmnCanvas" ref="bpmnCanvas" @contextmenu.prevent="openMenu"></div>
		</div>
		<customp-properties-panel 
			:notEdit="notEdit" 
			processId="Process_1" 
			ref="properties"
			processName='方案名称' 
			v-if="bpmnModeler" 
			:bpmnModeler="bpmnModeler">
		</customp-properties-panel>

		<div class="bpmn_menu" :style="menuStyle" v-if="menuShow">
			<div class="bpmn_menu_cell" @click="copyHandler">
				<span>COPY</span>
			</div>
			<div class="bpmn_menu_cell" @click="pasteHandler">
				<span>PASTE</span>
			</div>
		</div>

		<!-- <div class="bpmn_canvas_btn">
			<div class="canvas_btn" @click="saveDiagram">保存为bpmn</div>
			<div class="canvas_btn" @click="saveSVG">保存为svg</div>
		</div> -->
		
        <sub-process-view :popup.sync="processPopup" :processParams="processParams"></sub-process-view>
	</div>
</template>

<script>
import BpmnModeler from 'bpmn-js/lib/Modeler';
// 网格背景
import gridModule from 'diagram-js-grid';

// 工具、属性栏
import {
  BpmnPropertiesPanelModule, // 侧边栏
  BpmnPropertiesProviderModule, // camunda 侧边栏内容构建器
} from 'bpmn-js-properties-panel';


// 自定义工具栏部件
import paletteModule from './lib/index';

import copyPaste from "bpmn-js/lib/features/copy-paste";

// 自定义解析文件
import pvmModdleDescriptor from './lib/pvmModdleDescriptor/index.json';
import planModdleDescriptor from './lib/pvmModdleDescriptor/plan.json';

// 自定义属性面板组件
import custompPropertiesPanel from './lib/custompPropertiesPanel/inedx.vue'
import { duration } from 'moment';


var initialDiagram =
`<?xml version="1.0" encoding="UTF-8"?>
<bpmn:definitions xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:bpmn="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" id="Definitions_1" targetNamespace="http://bpmn.io/schema/bpmn">
  <bpmn:process id="Process_1" name="方案名称" isExecutable="false">
    <bpmn:extensionElements />
    <bpmn:startEvent id="StartEvent_1" />
  </bpmn:process>
  <bpmndi:BPMNDiagram id="BPMNDiagram_1">
    <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="Process_1">
      <bpmndi:BPMNShape id="_BPMNShape_StartEvent_2" bpmnElement="StartEvent_1">
        <dc:Bounds x="173" y="102" width="36" height="36" />
      </bpmndi:BPMNShape>
    </bpmndi:BPMNPlane>
  </bpmndi:BPMNDiagram>
</bpmn:definitions>
`;
export default {
	components: {
		custompPropertiesPanel,
		subProcessView: () => import('./subProcessView.vue')
	},
	props: {
		// 方案数据
		programmeDetails: {
			type: Object,
			default: () => null
		},
		// 编辑状态
		notEdit: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			bpmnModeler: null, // npmn实例
			initialDiagram: initialDiagram,
			programmeInfo: null, // 方案
			
            processPopup: false, // 子流程弹窗
            processParams: {}, // 子流程数据
			menuShow: false, // 自定义右击菜单显示状态
			menuStyle: { // 自定义右击菜单
				top: '-100px',
				left: '-100px'
			}
		}
	},
	watch: {
		'programmeDetails': {
			handler(val) {
				if (this.$route.query.edit) {
					if (val?.bpmn) {
						this.initialDiagram = val.bpmn;
						this.importXML() // 创建流程
					}
				}
			},
			deep: true,
		}
	},
	created() {
	},
	mounted() {
		// 初始化数据
		this.init();
	},
	methods: {
		/**
		 * 初始化数据
		 */
		init() {
			
			const canvas = this.$refs.bpmnCanvas;

			const dom = document.querySelector('.bpmn_content');
			
			// 生成实例
			this.bpmnModeler = new BpmnModeler({
				// 设置bpmn的绘图容器为上门获取的画布 element
				container: canvas,
				// 加入属性栏支持
				// propertiesPanel: {
				// 	parent: "#bpmn_properties_panel"
				// },
				additionalModules: [
					// 侧边栏
					BpmnPropertiesPanelModule, 
					// // 侧边栏内容构建器
					BpmnPropertiesProviderModule,
					copyPaste,

					// 自定义部件
					paletteModule,
					// 网格背景
					gridModule
				],
				moddleExtensions: {
					// 属性解析文件
					pvm: pvmModdleDescriptor,
					plan: planModdleDescriptor,
				},
				
				// keyboard: {
				// 	bindTo: document.body
				// }

			})
			if (!this.$route.query.edit) {
				this.importXML() // 创建流程
			}
			
		},

		/**
		 * 创建空白流程
		 */
         importXML () {
			
            this.bpmnModeler.importXML(this.initialDiagram);
		},
		
		/**
		 * 下载为bpmn格式
		 */
		async saveDiagram() {
			// 把传入的done再传给bpmn原型的saveXML函数调用
			return await this.bpmnModeler.saveXML({ format: true });
		},
		// 下载为SVG格式,done是个函数，调用的时候传入的
		async saveSVG() {
			const res = await this.bpmnModeler.saveSVG();
		},
		

        /**
         * 子级组件调用
         */
		 subProcessView(e) {
            
            this.processParams = e?.businessObject ?? {}
            this.processPopup = true;
        },

		/**
		 * 自定义右击事件
		 */
		openMenu(e) {
			
			this.menuShow = true;
			this.menuStyle.top = `${e.offsetY}px`;
			this.menuStyle.left = `${e.offsetX}px`;
		},
		
		/**
		 * copy
		 */
		async copyHandler() {
			
			const clipboard = this.bpmnModeler.get('clipboard');
			const selectElement = this.$refs.properties.selectionObj?.newSelection;
			
			const copyPaste = this.bpmnModeler.get('copyPaste');
			if (selectElement) {
				
				copyPaste.copy(selectElement);
				const copied = clipboard.get();
				
				// 创建text area
				const info = await this.copyToClipboard(JSON.stringify(copied))
				console.log(info)
				// localStorage.setItem('bpmnClipboard', JSON.stringify(copied));
			}﻿
		},

		/**
		 * 兼容拷贝
		 */
		copyToClipboard(textToCopy) {
			if (!window.isSecureContext) {
				this.$message.error('当前是非安全域，请在安全域下操作');
				return
			}
            // navigator clipboard 需要https等安全上下文
			// 创建text area
			let textArea = document.createElement("textarea");
			textArea.value = textToCopy;
			// 使text area不在viewport，同时设置不可见
			textArea.style.position = "absolute";
			textArea.style.opacity = 0;
			textArea.style.left = "-999999px";
			textArea.style.top = "-999999px";
			document.body.appendChild(textArea);
			textArea.focus();
			textArea.select();
           
			return new Promise((res, rej) => {
				if (navigator.clipboard) {
					// navigator clipboard 向剪贴板写文本
					navigator.clipboard.writeText(textToCopy).then(text => {
						res()
					}).catch(() => {
						rej()
					});
				} else {

					this.$message.error('剪切板对象不存在！');
				}
				
				textArea.remove();
			});
        },

		/**
		* paste
		*/
		async pasteHandler() {
			if (!window.isSecureContext) {

				this.$message.error('当前是非安全域，请在安全域下操作');
				return
			}
			const clipboard = this.bpmnModeler.get('clipboard');
			const copyPaste = this.bpmnModeler.get('copyPaste');
			const moddle = this.bpmnModeler.get('moddle');
			// const serializedCopy = localStorage.getItem('bpmnClipboard');
			
			const serializedCopy = await window.navigator.clipboard.readText();
			
			if (serializedCopy) {

				const parsedCopy = JSON.parse(serializedCopy, this.createReviver(moddle));
				clipboard.set(parsedCopy)
				copyPaste.paste();
			}
		},

		/**
		 *  重建模型类型
		 */
		createReviver(moddle) {

			var elCache = {};

			/**
			 * The actual reviewer that creates model instances
			 * for elements with a $type attribute.
			 *
			 * Elements with ids will be re-used, if already
			 * created.
			 *
			 * @param  {String} key
			 * @param  {Object} object
			 *
			 * @return {Object} actual element
			 */
			return function(key, object) {

			if (typeof object === 'object' && typeof object.$type === 'string') {

				var objectId = object.id;

				if (objectId && elCache[objectId]) {
				return elCache[objectId];
				}

				var type = object.$type;
				var attrs = Object.assign({}, object);

				delete attrs.$type;

				var newEl = moddle.create(type, attrs);

				if (objectId) {
				elCache[objectId] = newEl;
				}

				return newEl;
			}

			return object;
			};
		}
	}
}
</script>

<style lang="less">

.bpmn {
	height: 100%;
	width: 100%;
	position: relative;
	.bpmn_content {
		
		height: 100%;
		width: 100%;
	}
	.bpmn_canvas {
		height: 100%;
		width: 100%;
		.bjs-container {
			.bjs-powered-by {
				display: none;
			}
			.djs-overlay-container {
				.djs-overlays {
					.djs-overlay-context-pad {
						.djs-context-pad {
							width: 120px;
							.bpmn-icon-copy, .bpmn-icon-paste {
								width: 50px;
								&::before {
									width: auto;
								}
							}
							.bpmn-icon-copy {

								margin-right: 10px;
							}
							.bpmn-icon-copy:before {
								content: 'copy';
							}
							.bpmn-icon-paste:before {
								content: 'paste';
							}
						}
					}
				}
			}
		}
		.djs-palette {
			top: 80px;
		}
	}
	.bpmn_properties_panel {
		position: absolute;
		top: 0;
		right: 0;
		width: 300px;
		
	}
	.bpmn-icon-task.red {
		color: #cc0000 !important;
	}
	.icon-custom { /* 定义一个公共的类名 */
		border-radius: 50%;
		background-size: 65%;
		background-repeat: no-repeat;
		background-position: center;
	}

	.bpmn_menu {
		position: absolute;
		top: 50%;
		left: 50%;
		box-shadow: 0 0 8px 2px rgba(65, 64, 64, 0.3);
		padding: 4px 0;
		border-radius: 4px;
    	overflow: hidden;
		z-index: 9999;
		background: #fff;
		.bpmn_menu_cell {
			width: 100px;
			height: 32px;
			padding: 0 10px;
			background: #FFFFFF;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-size: 14px;
			&:hover {
				background: #0266FF;
				color: #FFFFFF;
			}
		}
	}

	

	.bpmn_canvas_btn {
		position: absolute;
		bottom: 10px;
		display: flex;
		margin-left: 100px;
		user-select: none;
		.canvas_btn {
			padding: 0 10px;
			height: 40px;
			line-height: 40px;
			text-align: center;
			background: #FFFFFF;
			border: 1px solid #0266FF;
			border-radius: 6px;
			color: #0266FF;
			margin-right: 20px;
			&:hover {
				cursor: pointer;
			}
		}
	}
}
.bpmnView {
	.djs-context-pad {
		display: none;
	}
	.djs-palette {
		display: none;
	}
}
</style>
