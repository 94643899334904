
import inherits from "inherits";
import RuleRrovider from 'diagram-js/lib/features/rules/RuleProvider';
export default function ResizeAllRules(eventBus) {
    RuleRrovider.call(this, eventBus);
}

inherits(ResizeAllRules, RuleRrovider);
ResizeAllRules.$inject = ['eventBus'];

ResizeAllRules.prototype.init = function() {
    this.addRule('shape.resize', 1500, function() {
        return true;
    });
}




