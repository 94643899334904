
const STATICPATH = '' // 静态文件路径
const customElement = { // 自定义元素的配置
    // 'bpmn:StartEvent': {
    //     'field': 'start',
    //     'title': '开始节点',
    //     'attr': { x: 0, y: 0, width: 40, height: 40 }
    // },
    // 'bpmn:EndEvent': {
    //     'field': 'end',
    //     'title': '结束节点',
    //     'attr': { x: 0, y: 0, width: 40, height: 40 }
    // },
    // 'bpmn:SequenceFlow': {
    //     'field': 'flow',
    //     'title': '连接线',
    // },
    // 'bpmn:Task': {
    //     'field': 'rules',
    //     'title': '任务',
    //     'attr': { x: 0, y: 0, width: 48, height: 48 }
    // },
    // 'bpmn:ExclusiveGateway': {
    //     'field': 'decision',
    //     'title': '网关',
    //     'attr': { x: 0, y: 0, width: 48, height: 48 }
    // },
}
const hasLabelElements = ['bpmn:StartEvent', 'bpmn:EndEvent', 'bpmn:ExclusiveGateway'] // 一开始就有label标签的元素类型

export {
    STATICPATH,
    customElement,
    hasLabelElements
}