<template>
    <div class="createProgramme">
        <div class="createProgramme_base">
            <div class="createProgramme_base_form">
                <el-form 
                    :inline="true" 
                    :model="createForm" 
                    :rules="createRules" 
                    ref="createForm">
                    <el-form-item label="方案名称：" prop="name">
                        <el-input v-model="createForm.name" placeholder="请输入方案名称"></el-input>
                    </el-form-item>
                    <el-form-item label="年级：" prop="grade">
                        <el-select v-model="createForm.grade" placeholder="请选择年级">
                            <el-option 
                                v-for="item in gradeList" 
                                :key="`${item.gradeCode}grade`" 
                                :label="item.gradeName" 
                                :value="item.gradeCode">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="学科：" prop="subjectId">
                        <el-select v-model="createForm.subjectId" placeholder="请选择学科">
                            <el-option 
                                v-for="item in subjectList" 
                                :key="`${item.subjectCode}subject`" 
                                :label="item.subjectName" 
                                :value="item.subjectCode">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="场景：" prop="scene">
                        <el-select v-model="createForm.scene" placeholder="请选择场景">
                            <el-option 
                                v-for="item in sceneList" 
                                :key="`${item.code}scene`" 
                                :label="item.name" 
                                :value="item.code">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="类型：">
                        <el-radio-group v-model="createForm.subType">
                            <el-radio :label="1">流程</el-radio>
                            <el-radio :label="2">模板</el-radio>
                            <el-radio :label="3">流程/模板</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    
                </el-form>
            </div>

            <div class="createProgramme_base_btn">

                <el-button type="primary" @click="createHandler" v-if="$route.query.edit != 2">保存</el-button>
            </div>
        </div>
        <div class="createProgramme_content">
            <createBpmn :programmeDetails="programmeDetails" ref="bpmn" :notEdit="$route.query.edit > 1" ></createBpmn>
        </div>

    </div>
</template>

<script>
import $http from '@/service/index'
import createBpmn from './bpmn.vue'
export default {
    name: 'createProgramme',
    components: {createBpmn},
    data() {
        return {
            programmeDetails: null, // 方案详情 
            gradeList: [], // 学年列表
            sceneList: [], // 场景列表
            subjectList: [], // 学科列表
            createRules: { // 校验规则
                name: [{required: true, message: '请输入方案名称', trigger: 'blur'}],
                grade: [{required: true, message: '请选择年级', trigger: 'change'}],
                scene: [{required: true, message: '请选择场景', trigger: 'change'}],
                subjectId: [{required: true, message: '请选择学科', trigger: 'change'}],
            },
            
            createForm: { // 方案基础信息
                "grade": "", // 年级
                "name": "", // 方案名称
                "scene": '', // 场景
                "subjectId": '', // 学科
                "subType": 3, // 方案类型 1 == 流程 2 == 模板 3 == 流程加模板
            },

        }
    },
    provide() {
        return {
            parentThis: this
        }
    },
    watch: {
        'createForm.grade': {
            handler(val) {
                if (val) {
                    this.createForm.subjectId = '';
                    // 获取学科
                    this.getSubject(val);
                }
            },
        }
    },
    
    created() {
        
		// 初始化数据
		this.init();
        // 获取年级
        this.getGrades();
        // 获取筛选码表
        this.getEnum();
    },
    mounted() {
        // 改变页面元素
        this.changeDom();
    },
    methods: {
        /**
         * 改变页面元素
         */
        changeDom() {
            // omp嵌入页面
            if (this.$route.query.enterOmp == 1) {

                const dom = document.querySelector('.createProgramme_base');
                const headerDom = document.querySelector('.el-header');
                dom.style.display = 'none'
                headerDom.style.display = 'none'
            }
        },
        
		/**
		 * 初始化数据
		 */
		init() {
			if (this.$route.query.edit) {
                // 获取方案详情
                this.getProgrammeDetails();
			}
		},
        /**
         * 获取方案详情
         */
        async getProgrammeDetails() {
            const params = {
                data: {
                    id: this.$route.query.id,
                    needBpmn: true,
                },
                config: {
                    showLoading: true,
                    text: '加载中...',
                }
            };
            const res = await $http.getProgrammeDetails(params);

            if(res.code == '000000') {
                this.programmeDetails = res?.result ?? null;
                if (res?.result) {
                    for(let key in this.createForm){
                        this.createForm[key] = this.programmeDetails[key]
                    }
                    if (!this.createForm['subType']) {
                        this.createForm['subType'] = 1;
                    }
                    this.$nextTick(() => {
                        this.createForm.subjectId = this.programmeDetails.subjectId
                    })
                }
            }
        },

        /**
         * 获取学年数据
         */
         async getGrades() {
            const res = await $http.getGrades();
            if (res.code == '000000') {
                this.gradeList = res?.grades ?? [];
            }
        },

        /**
         * 获取学科数据
         */
        async getSubject(val) {
            const params = {
                data: {
                    "gradeCode": val,
                },
                config: {
                    showLoading: true,
                    text: '获取学科中...'
                }
            }
            
            const res = await $http.getSubject(params);
            if (res.code == '000000') {
                this.subjectList = res?.subjects ?? [];
            }
        },

        /**
         * 获取筛选码表
         */
        async getEnum() {
            const res = await $http.getEnum();
            if (res.code == '000000') {
                this.sceneList = res?.result?.sceneEnum ?? [];
            }
        },

        /**
         * 创建/更新方案模板
         */
        async createHandler() {
            let bpmnInfo = await this.$refs.bpmn.saveDiagram();
            console.log(bpmnInfo)
            await this.$refs.createForm.validate();
            // await this.$refs.bpmn.$refs.properties.checkVarComplete()
            
            if (!bpmnInfo || !bpmnInfo.xml) {
                this.$message.error('方案流程创建失败');
                return
            }
            let params = {
                data: {
                    bpmn: bpmnInfo.xml,
                    ...this.createForm
                },
                config: {
                    showLoading: true,
                    text: '保存中...'
                }
            }
            // 更新方案模板
            if (this.$route.query.edit) {
                params.data.modelId = this.programmeDetails.modelId;
                params.data.id = this.programmeDetails.id;
            }
            const res = await $http.saveProgramme(params);
            if (res.code === '000000') {
                this.$message.success(this.$route.query.edit ? '更新成功' : '创建成功');
                this.$router.go(-1);
            }
        },
    }
}
</script>

<style lang="less" scoped>

.createProgramme {
    height: 100%;
    display: flex;
    flex-direction: column;
    .createProgramme_base {
        display: flex;
        padding: 20px;
        box-sizing: border-box;
        border-bottom: 1px solid #ededed;
        .createProgramme_base_form {
            flex: 1;
            width: 0;
            /deep/ .el-form {
                .el-form-item {
                    margin-bottom: 0;
                }
            }
        }
    }
    .createProgramme_content {
        flex: 1;
        height: 0;
    }
}
</style>