
const dataTypes = [
    {
        code: '1',
        type: 'TypeString',
        check: true,
    },
    {
        code: '1',
        type: 'TypeNumber',
        check: true,
    },
    {
        code: '1',
        type: 'TypeBoolean',
        check: true,
    },
    {
        code: '2',
        type: 'TypeDate',
        check: true,
    },
    {
        code: '10',
        type: 'TypeListBoolean',
    },
    {
        code: '10',
        type: 'TypeListNumber',
    },
    {
        code: '10',
        type: 'TypeListString',
    },
    {
        code: '10',
        type: 'TypeListDate',
    },
    {
        code: '10',
        type: 'TypeListMapBoolean',
    },
    {
        code: '10',
        type: 'TypeListMapDate',
    },
    {
        code: '10',
        type: 'TypeListMapNumber',
    },
    {
        code: '10',
        type: 'TypeListMapString',
    },
    {
        code: '20',
        type: 'TypeMapBoolean',
    },
    {
        code: '20',
        type: 'TypeMapNumber',
    },
    {
        code: '20',
        type: 'TypeMapString',
    },
    {
        code: '20',
        type: 'TypeMapDate',
    },
    {
        code: '20',
        type: 'TypeMapListBoolean',
    },
    {
        code: '20',
        type: 'TypeMapListNumber',
    },
    {
        code: '20',
        type: 'TypeMapListString',
    },
    {
        code: '20',
        type: 'TypeMapListDate',
    },
    {
        code: '1000',
        type: 'splitLine',
    },];
export {
    dataTypes
}