import inherits from 'inherits';

import BaseRenderer from 'diagram-js/lib/draw/BaseRenderer';

import {
    STATICPATH,
    customElement,
    hasLabelElements} from './customConfig';

import {
    append as svgAppend,
    create as svgCreate
} from 'tiny-svg';

export default function NyanRender(eventBus) {
    BaseRenderer.call(this, eventBus, 1500);
    this.canRender = function(element) {
        return true;
    };

    function drawElements(parent, shape) {
        const type = shape.type; // 获取元素类型
        if (type != 'label') {
            if (customElement[type]) {
                return drawCustomElements(parent, shape);
            }
            // const nativeShape = BaseRenderer.drawShape(parent, shape);
            // return nativeShape;
        } else {
            shape
        }
    }

    function drawCustomElements(parent, shape) {

        const { type } = shape;
        const { field, attr } = customElement[type];

        const url = `${STATICPATH}/${field}`;
        // let url = Cat.dataURL;

        let catGfX = svgCreate('image', {
            x: attr.x || 0,
            y: attr.y || 0,
            height: attr.height || shape.height,
            width: attr.width || shape.width,
            href: url,
        });

        svgAppend(parent, catGfX);

        // 判断是否有name属性，决定是否渲染lable
        if(hasLabelElements.includes(type) && shape.businessObject.name) {
            const text = svgCreate('text', {
                x: attr.x || 0,
                y: attr.y + shape.height + 16 || 0,
                'font-size': '12',
                fill: '#3c3c3c'
            })

            text.innerHTML = shape.businessObject.name;
            svgAppend(parent, text);
        }

        return catGfX;
    }

    this.drawShape = function(parent, shape) {
        
        return drawElements(parent, shape);
    }
}
inherits(NyanRender, BaseRenderer)
NyanRender.$inject = ['eventBus'];